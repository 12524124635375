<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'

  export default {
    name: 'Sicurezza',

    metaInfo () {
      return {
        title: this.$i18n.t('title'),
      }
    },

    extends: View,

    mixins: [
      LoadSections([
        'hero-alt',
        'safety',
        'relied',
        'sgd-presentation',
        'info-alt',
      ]),
    ],

    props: {
      id: {
        type: String,
        default: 'safety',
      },
    },
  }
</script>
<i18n>
{
	"en": {
		"title": "Safety"
	},
	"it": {
		"title": "Sicurezza"
	}
}
</i18n>
